<script setup lang="ts">
type Props = {
  id: string
  value: string
  options: { label: string; value: string | null }[]
}

const props = defineProps<Props>()
const emit = defineEmits<(e: 'select:value', value: string) => void>()
</script>

<template>
  <select
    :id="props.id"
    class="bg-gray-50 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"
    :class="props.value === '' ? 'text-gray-500 dark:text-gray-400' : 'text-gray-900 dark:text-white'"
    @change="emit('select:value', ($event.target as HTMLSelectElement).value)"
  >
    <option
      v-for="option of props.options"
      :key="`${props.id}-${option.value}`"
      :value="option.value"
      :selected="props.value === option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>
