<script setup lang="ts">
import { onBeforeMount, onMounted, ref } from 'vue'

import Logo from '@/assets/logo.svg'
import Spinner from '@/components/Spinner.vue'
import SearchResults from '@/pages/SearchResults.vue'
import { fetchAccessToken } from './fetchAccessToken'

onBeforeMount(() => {
  // read from browser setting of dark theme and move to session storage.
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue === 'dark' || window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.classList.add('dark')
  }
})

const keyID = ref(localStorage.getItem('keyID') || '')
const privateKey = ref(localStorage.getItem('privateKey') || '')
const issuer = ref(localStorage.getItem('issuer') || '')
const audience = ref('ankor.io')
const scopes = ref('website:read:*')
const accessToken = ref('')
const isAuthorizing = ref(false)

onMounted(async () => {
  isAuthorizing.value = true
  accessToken.value = await fetchAccessToken(keyID.value, privateKey.value, issuer.value, audience.value, scopes.value)
  isAuthorizing.value = false
})

const getAccessToken = async (e: Event) => {
  e.preventDefault()
  // Set keyID, privateKey and issuer in local storage
  localStorage.setItem('keyID', keyID.value)
  localStorage.setItem('privateKey', privateKey.value)
  localStorage.setItem('issuer', issuer.value)

  // Get access token
  isAuthorizing.value = true
  accessToken.value = await fetchAccessToken(keyID.value, privateKey.value, issuer.value, audience.value, scopes.value)
  isAuthorizing.value = false
}
</script>
<template>
  <div class="w-full min-h-dvh bg-white dark:bg-gray-900">
    <div id="modal"></div>
    <!-- header -->
    <header class="max-w-screen-xl mx-auto p-5 flex justify-center">
      <img :src="Logo" alt="ODYC" class="h-8" />
    </header>
    <!-- Form to get access token -->
    <form v-if="!accessToken" class="max-w-md mx-auto p-5">
      <!-- kid -->
      <div class="mb-5">
        <label for="kid" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Key ID </label>
        <input
          type="text"
          id="kid"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your Key ID here"
          required
          v-model="keyID"
        />
      </div>

      <!-- private_key -->
      <div class="mb-5">
        <label for="privateKey" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Private Key
        </label>
        <textarea
          id="privateKey"
          rows="8"
          class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
          placeholder="Your Private key here"
          required
          v-model="privateKey"
        ></textarea>
      </div>

      <!-- issuer -->
      <div class="mb-5">
        <label for="issuer" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Issuer </label>
        <input
          type="text"
          id="issuer"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your Issuer here"
          required
          v-model="issuer"
        />
      </div>

      <!-- subject -->
      <div class="mb-5">
        <label for="subject" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Subject </label>
        <input
          type="text"
          id="subject"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your Subject here"
          required
          v-model="issuer"
        />
      </div>

      <!-- audience -->
      <div class="mb-5">
        <label for="audience" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Audience </label>
        <input
          type="text"
          id="audience"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your Audience here"
          required
          v-model="audience"
        />
      </div>

      <!-- scopes -->
      <div class="mb-5">
        <label for="scopes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Scopes </label>
        <input
          type="text"
          id="scopes"
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your Scopes here"
          required
          v-model="scopes"
        />
      </div>

      <!-- authorize -->
      <button
        type="submit"
        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        @click="getAccessToken"
      >
        <Spinner v-if="isAuthorizing" class="w-5 h-5" />
        <span v-else>Authorize</span>
      </button>
    </form>

    <!-- Search Results -->
    <SearchResults v-else :accessToken="accessToken" />
  </div>
</template>
