import * as jose from 'jose'

/**
 * Retrieves the access token by performing the auth dance
 */

const ALORGITHM = 'RS256'

export const fetchAccessToken = async (
  keyID: string,
  privateKey: string,
  issuer: string,
  audience: string,
  scopes: string,
): Promise<string> => {
  if (!issuer || !audience || !scopes || !privateKey || !keyID) {
    return ''
  }

  try {
    // isAuthorizing.value = true
    const ankorPrivateKey = await jose.importPKCS8(privateKey, ALORGITHM)
    const jwt = await new jose.SignJWT({ scopes: [scopes] })
      .setProtectedHeader({ alg: ALORGITHM, typ: 'JWT', kid: keyID })
      .setIssuer(issuer)
      .setSubject(issuer)
      .setAudience(audience)
      .setIssuedAt(new Date().getTime())
      .setExpirationTime('45 minutes')
      .sign(ankorPrivateKey)

    const formData = new FormData()
    formData.set('grant_type', 'urn:ietf:params:oauth:grant-type:jwt-bearer')
    formData.set('assertion', jwt)
    const response = await fetch('/iam/oauth/token', {
      method: 'POST',
      body: formData,
    })

    if (response.status === 200) {
      const { access_token } = await response.json()
      return access_token
      // isAuthorizing.value = false
    }

    console.error(response.json())
    return ''
  } catch (e) {
    console.error(e)
    return ''
  }
}
